import React from "react"
import Button from "~/components/reusable/Button"
import Wrapper from "~/components/reusable/Wrapper"
import Layout from "../components/Layout"
import bg from "~/images/brookes/heart-right.png"

import { fontSizes } from "~/styles/lookups"

const NotFoundPage = () => (
  <Layout 
    meta={{}} 
    title="Page not found"
    transHead={true}
  >
    <div 
      className={`border-t-8 border-solid border-theme-primary bg-theme-tertiary bg-no-repeat bg-right-bottom bg-[length:200px] md:bg-[length:auto]`}
      style={{backgroundImage: `url(${bg})`}}
    >
      <Wrapper>
        <div className={`py-32 pt-48 md:py-52 lg:pt-64 lg:pb-60 max-w-[386px] ml-0 mr-auto [&_.brew-button]:ml-3`}>
          <h1 className={`px-3 mb-8 ${fontSizes['h1']}`}>404</h1>
          <h2 className={`px-3 mb-8 ${fontSizes['h2']}`}>Ooops!!! Don't break our hearts</h2>
          <p className={`px-3 mb-12 font-body ${fontSizes['default']}`}>Even the things we love break sometimes, Thanks for your patience whilst we put the pieces back together.</p>
          <Button 
            className={``}
            link={`/`}
            text={`Visit Our Homepage`}
            buttonStyle={`tertiary`}           
          />
        </div>
      </Wrapper>
    </div>
  </Layout>
)

export default NotFoundPage
